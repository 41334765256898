<template>
  <div>
    <ParallaxComponent
      :selectedImage="selectedImage"
      :title="'Experience Nature\'s Majesty with Us'"
      :paragraph="''"
    />

    <section>
      <article class="about-us-art">
        <div class="container d-flex align-items-center justify-content-center">
          <div class="about-us-art-introduction">
            <h2 style="color: #17351e">Overview</h2>
            <p class="sub-title"></p>
            <p class="content-paragraph" style="color:antiquewhite">
              At Acacia Wilderness Lodge Mweya, we are driven by a passion for
              what we do. Our team is dedicated to creating unforgettable
              experiences for every guest. We inspire families to travel
              together, providing spacious family rooms with amenities that
              ensure comfort for children and peace of mind for parents.
              <br />
              <br /> 
              We offer couples the perfect setting with beautiful rooms and
              stunning views that allow them to relax and celebrate their love.
              <br /> 
              <br /> 
              For corporate organizations, we provide versatile spaces ideal for
              team retreats, complete with areas for team-building activities.
              <br /> 
              <br /> 
              We also understand the importance of making travel accessible,
              which is why we offer competitive rates for domestic tourists,
              enabling them to fulfill their travel dreams without breaking the
              bank. Above all, we are committed to sustainability, with
              eco-friendly practices such as a plastic-free operation and the
              responsible use of resources to help conserve nature and protect
              wildlife habitats.
              <br />
              <br /> 
              At Acacia, we put our heart into everything we do. The next time
              you're planning a vacation, we invite you to experience the
              difference at Acacia Wilderness Lodge, Mweya—where comfort,
              nature, and unforgettable moments await.
            </p>
          </div>
        </div>

        <div
          class="container d-flex align-items-center justify-content-center"
        ></div>
      </article>
    </section>

    <section>
      <article>
        <div class="room-accommodation-grid">
          <div>
            <img src="../../assets/images/bar.webp" />
          </div>
          <div>
            <div>
              <h2>OUR BAR</h2>
              <p class="sub-title">A mastery of Creativity</p>
              <p class="content-paragraph">
                At Acacia Wilderness Mweya, we are dedicated to delivering an
                authentic and affordable wilderness experience. Our lodge
                harmoniously integrates with the natural landscape, providing
                exceptional comfort while preserving the stunning beauty of
                Queen Elizabeth National Park.
              </p>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article>
        <div class="room-accommodation-grid-reserve">
          <div class="d-flex align-items-center">
            <div>
              <h2>Accomodation</h2>
              <p class="sub-title">
                Cozy accomodation in the heart of the wilderness
              </p>
              <p class="content-paragraph">
                Acacia Wilderness Mweya features exclusive bungalows that offer
                sweeping views of the lake and the surrounding wilderness. Each
                room is designed with space, comfort, and privacy in mind,
                ensuring a relaxing stay.
              </p>
            </div>
          </div>
          <div>
            <img src="../../assets/images/accomodation-outdoor.webp" />
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="our-people">
        <div class="container d-flex align-items-center justify-content-center">
          <div class="our-people-introduction text-center">
            <h2 style="color: #17351e">
              <!-- The people at the heart of our project -->
              The Acacia Promise
            </h2>
            <p class="sub-title">
              <!-- Featuring the Flavors of Savanna -->
            </p>
            <p class="content-paragraph">
              <!-- Acacia Wilderness Mweya—a vibrant, breathing, and living ecosystem—perfectly showcases the rich biodiversity of Uganda and the 
              remarkable wildlife that thrives here. Stepping into the lodge is to uncover the hidden wonders of nature. 
              It is a world unto itself where, from the tiniest insects to the largest mammals, flora and fauna envelop 
              you in a mesmerizing dance like nowhere else. The magic of this place is tangible, and its raw, untouched 
              beauty is so vivid you can almost feel it. This natural wonder is what drew us here, inspiring us to share 
              this unique wilderness experience with the world. -->
              At Acacia Wilderness Mweya, we pride ourselves on providing guests
              with personalized service, knowledgeable staff, and an immersive
              experience in nature. Our commitment to sustainability and
              community involvement ensures that your stay is not only luxurious
              but also environmentally conscious.
            </p>

            <div>
              <img src="../../assets/images/acacia-promise.webp" />
            </div>
          </div>
        </div>

        <div class="container">
          <div class="our-people-grid">
            <div>
              <!-- Acacia Wilderness Mweya would not be what it is without the collaboration of the local community. 
                From the beginning, we have worked hand-in-hand with those living in the areas surrounding Queen Elizabeth National Park, 
                implementing innovative programs that allow community members and lodge employees to share in the success of our enterprise. 
                We provide numerous job opportunities for local residents, including professional training and English classes, 
                fostering growth and development within the community. -->
            </div>
            <div>
              <!-- These same community members share their invaluable knowledge of the surrounding lands and wildlife. 
                Our guides, lodge staff, and a team of conservationists can tell you stories of their past experiences as hunters, 
                loggers, or miners in this very region before the establishment of Acacia Wilderness Mweya. 
                Their relationship with the lodge has been truly transformative. Furthermore, these communities are the primary 
                providers of agricultural and other food products used at the lodge, ensuring a connection to the land that 
                enriches our culinary offerings. -->
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <section>
        <article>
          <div class="the-restaurant">
            <img src="../../assets/images/acacia_mweya3.jpg" />
            <div class="the-restaurant-content">
              <div class="container">
                <div class="the-content">
                  <h2>Dining Experience</h2>
                  <p class="sub-title">
                    <!-- Discover the flavors of Uganda -->
                  </p>
                  <p class="content-paragraph">
                    <!-- The lodge’s impressive dining area is two stories high, providing a spectacular space for a vibrant gourmet experience. 
                    Acacia Wilderness Mweya’s chefs draw inspiration from the rich diversity of Ugandan cuisine and local ingredients, 
                    making the most of the lodge’s stunning location within Queen Elizabeth National Park and offering guests 
                    a taste of the best flavors the region has to offer. -->
                    Our culinary team sources fresh, organic ingredients from
                    the region, including vibrant vegetables and premium coffee
                    beans grown along the riverbanks and mountain slopes. Guests
                    enjoy healthy, balanced meals tailored to individual
                    preferences, served in an inviting setting.
                  </p>
                  <el-button
                    class="acacia-btn"
                    @click="$router.push({ path: 'dining' })"
                    >EXPLORE MORE</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </article>
      </section>
    </section>

    <section>
      <article>
        <ReviewComponent />
      </article>
    </section>

    <!-- <ReservationArticleComponent /> -->
  </div>
</template>

<script>
// import ReservationArticleComponent from "../../components/website/home/reservation-article-component.vue";
import ReviewComponent from "../../components/website/home/review-component.vue";
import ParallaxComponent from "../../components/website/pallarax-component.vue";

export default {
  components: {
    ReviewComponent,
    // ReservationArticleComponent,
    ParallaxComponent,
  },

  data() {
    return {
      selectedImage: require("../../assets/images/acacia_mweya1.jpg"),
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.about-us-header {
  background-image: url("../../assets/images/acacia_mweya1.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  height: 100vh;
  width: 100%;
  position: relative;
}

.about-us-header > div {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 180px;
}
.about-us-header > div .about-us-header-content {
  width: 50%;
}
.about-us-header h1 {
  text-transform: uppercase;
  font-size: 3em;
  font-weight: 800;
}
.the-restaurant {
  height: 100vh;
  position: relative;
}

.the-restaurant img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.the-restaurant .the-restaurant-content {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;

  display: flex;
  align-items: center;
}

.the-restaurant .the-restaurant-content .the-content {
  background-color: #17351e97;
  color: #ddd0a9;
  width: 50%;
  min-height: 60vh;
  padding: 50px;
  border: 1px solid #ddd0a9;
  border-radius: 2px;
}
.the-restaurant .the-restaurant-content .the-content h2 {
  font-size: 2em;
  font-weight: 600;
}
.the-restaurant .the-restaurant-content .the-content .sub-title {
  font-size: 1.5em;
  font-weight: 500;
}
.the-restaurant .the-restaurant-content .the-content .content-paragraph {
  font-size: 1em;
  font-weight: 400;
}

.acacia-btn {
  background-color: transparent;
  color: white;
  border: 2px solid #ddd0a9;
  border-radius: 0px;
  font-size: 1.2em;
  margin: 0px !important;
  transition: 0.5s;
  width: 200px;
}

.acacia-btn:hover {
  background-color: #ddd0a9;
  color: black !important;
  transition: 0.5s;
}

.acacia-btn-reverse {
  background-color: #ddd0a9;
  color: white !important;
  border: 2px solid #ddd0a9;
  border-radius: 0px;
  font-size: 1.2em;
  margin: 0px !important;
  transition: 0.5s;
  width: 200px;
}

.acacia-btn-reverse:hover {
  background-color: transparent;
  color: #ddd0a9;
  transition: 0.5s;
}
.room-accommodation-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.room-accommodation-grid > div,
.room-accommodation-grid-reserve > div {
  background-color: #17351e;
  color: white;
  height: 100vh;
}

.room-accommodation-grid > div h2,
.room-accommodation-grid-reserve > div h2 {
  font-size: 2em;
  font-weight: 600;
  color: #ddd0a9;
}
.room-accommodation-grid > div .sub-title,
.room-accommodation-grid-reserve > div .sub-title {
  font-size: 1.5em;
  font-weight: 500;
  color: #ddd0a9;
}
.room-accommodation-grid > div .content-paragraph,
.room-accommodation-grid-reserve > div .content-paragraph {
  font-size: 1em;
  font-weight: 400;
}

.room-accommodation-grid > div img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.room-accommodation-grid > div:nth-child(2) {
  padding: 100px;
  display: flex;
  align-items: center;
}

.room-accommodation-grid-reserve {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.room-accommodation-grid-reserve > div {
  height: 100vh;
}

.room-accommodation-grid-reserve > div img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.room-accommodation-grid-reserve > div:first-child {
  padding: 100px;
}

.about-us-art {
  background-image: url(../../assets/images/acacia_mweya3.jpg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px;
  height: 200vh;
}

.about-us-art-introduction {
  width: 80%;
  background-color: #17351ea1;
  padding: 15px;
 border-radius: 5px;
}
.about-us-art-introduction h2 {
  font-size: 2em;
  font-weight: 600;
  color: #ddd0a9;
}
.about-us-art-introduction .sub-title {
  font-size: 1.5em;
  font-weight: 500;
  color: #ddd0a9;
}
.about-us-art-introduction .content-paragraph {
  font-size: 1em;
  font-weight: 400;
}

.about-us-art-images {
  margin-top: 100px;
  margin-bottom: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.about-us-art-images > div {
  height: 60vh;
}
.about-us-art-images > div img {
  width: 100%;
  height: 60vh;
}
.about-us-art-images > div:first-child {
  height: 60vh;
}
.about-us-art-images > div:nth-child(2) {
  position: relative;
}
.about-us-art-images > div:nth-child(2) img {
  position: absolute;
  top: 60px;
  right: 30px;
}
.about-us-art-images > div:nth-child(3) {
  position: relative;
}
.about-us-art-images > div:nth-child(3) img {
  position: absolute;
  right: 0px;
  width: 120%;
  height: 50vh;
  object-fit: cover;
}

.about-us-art h2 {
  font-size: 3em;
  font-weight: 800;
  text-transform: uppercase;
}

.our-people {
  padding-top: 100px;
  padding-bottom: 100px;
  min-height: 100vh;
}
.our-people-introduction {
  width: 70%;
}
.our-people-introduction h2 {
  font-size: 2em;
  font-weight: 600;
  color: #ddd0a9;
}
.our-people-introduction .sub-title {
  font-size: 1.5em;
  font-weight: 500;
  color: #ddd0a9;
}
.our-people-introduction .content-paragraph {
  font-size: 1em;
  font-weight: 400;
}

.our-people img {
  width: 100%;
  height: 50vh;
  object-fit: cover;
}

.our-people-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 50px;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .the-best-alternative {
    background-color: #ddd0a9;
    padding-top: 50px;
    padding-bottom: 50px;
    height: 100%;
  }
  .the-best-alternative-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .the-best-alternative-grid > div h2 {
    text-align: center;
  }
  .the-best-alternative-grid > div p {
    text-align: center;
  }
  .room-accommodation-grid {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
  }
  .room-accommodation-grid-reserve {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
    grid-auto-flow: row;
  }
  .room-accommodation-grid > div:first-child {
    height: 40vh;
  }
  .room-accommodation-grid > div:first-child img {
    height: 40vh;
  }
  .room-accommodation-grid > div:nth-child(2) {
    height: 100%;
    padding: 20px;
  }
  .room-accommodation-grid-reserve > div:nth-child(2) {
    height: 40vh;
    grid-row: 1;
  }
  .room-accommodation-grid-reserve > div:nth-child(2) img {
    height: 40vh;
  }
  .room-accommodation-grid-reserve > div:first-child {
    height: 100%;
    padding: 20px;
  }
  .room-accommodation-grid-reserve > div img {
    height: 50vh;
  }
  .room-accommodation-grid > div:nth-child(2) {
    padding: 20px;
  }
  .room-accommodation-grid-reserve > div:first-child {
    padding: 0px;
  }
  .room-accommodation-grid > div p {
    font-size: 1em;
  }
  .room-accommodation-grid-reserve > div p {
    font-size: 1em;
  }
  .accommodation-body h2 {
    padding-top: 50px;
  }
  .acacia-btn {
    width: 100%;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .our-people {
    padding-top: 50px;
    padding-bottom: 50px;
    min-height: 100vh;
  }
  .our-people-introduction {
    width: 90%;
  }
  .our-people img {
    height: 40vh;
  }
  .our-people-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 50px;
  }

  .about-us-art-introduction {
    width: 100%;
  }
  .about-us-art-introduction h2 {
    font-size: 1.6em;
    font-weight: 600;
    color: #ddd0a9;
  }
  .about-us-art-introduction .sub-title {
    font-size: 1.2em;
    font-weight: 500;
    color: #ddd0a9;
  }
  .about-us-art-introduction .content-paragraph {
    font-size: 1em;
    font-weight: 400;
  }

  .the-best-alternative {
    background-color: #ddd0a9;
    padding-top: 50px;
    padding-bottom: 50px;
    height: 100%;
  }
  .the-best-alternative-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .the-best-alternative-grid > div h2 {
    text-align: center;
  }
  .the-best-alternative-grid > div p {
    text-align: center;
  }
  .room-accommodation-grid {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
  }
  .room-accommodation-grid-reserve {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
    grid-auto-flow: row;
  }
  .room-accommodation-grid > div:first-child {
    height: 40vh;
  }
  .room-accommodation-grid > div:first-child img {
    height: 40vh;
  }
  .room-accommodation-grid > div:nth-child(2) {
    height: 100%;
    padding: 20px;
  }
  .room-accommodation-grid-reserve > div:nth-child(2) {
    height: 40vh;
    grid-row: 1;
  }
  .room-accommodation-grid-reserve > div:nth-child(2) img {
    height: 40vh;
  }
  .room-accommodation-grid-reserve > div:first-child {
    height: 100%;
    padding-top: 50px;
  }
  .room-accommodation-grid-reserve > div img {
    height: 50vh;
  }
  .room-accommodation-grid-reserve > div:first-child {
    padding: 20px;
  }
  .room-accommodation-grid > div p {
    font-size: 1em;
  }
  .room-accommodation-grid-reserve > div p {
    font-size: 1em;
  }
  .accommodation-body h2 {
    padding-top: 50px;
  }
  .acacia-btn {
    width: 100%;
  }

  .the-restaurant {
    height: 100vh;
    position: static;
  }
  .the-restaurant img {
    height: 40vh;
    width: 100%;
    object-fit: cover;
  }
  .the-restaurant .the-restaurant-content {
    background-color: #17351e;
    position: static;
    width: 100%;
  }
  .the-restaurant .the-restaurant-content .the-content {
    background-color: #17351e;
    width: 100%;
    height: 60vh;
    padding: 30px 10px 10px 0px;
    border: none;
  }
}
</style>